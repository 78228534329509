import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { FC } from 'react';
import { Role } from '@/models/user';
import useSession from '@/hooks/useSession';

const RouteGuard: FC<{ allowedRoles: Role[] }> = ({ allowedRoles }) => {
  const { session } = useSession();
  const location = useLocation();

  return session.roles.find((role) => allowedRoles?.includes(role)) ? (
    <Outlet />
  ) : session?.email ? (
    <Navigate to="/not-found" state={{ from: location }} replace />
  ) : (
    <Navigate to="/sign-in" state={{ from: location }} replace />
  );
};

export default RouteGuard;

import InputBox from '../../components/InputBox';
import Conversation from './components/Conversation';
import styles from './thread.module.scss';
import Sidebar from './components/Sidebar';
import Title from './components/Title';
import ThreadOptions from './components/ThreadOptions';
import Disclaimer from '@/components/Disclaimer';
import { SidebarProvider } from './context/SidebarProvider';
import SEOHelmet from '@/components/SEOHelmet';
import useThread from './hooks/useThread';
import { useEffect, useState } from 'react';
import { ThreadBlock } from '@/models/thread';

const Thread = () => {
  const { id, title, blocks } = useThread();
  const [totalCost, setTotalCost] = useState<number>(0);

  useEffect(() => {
    if (!import.meta.env.VITE_SHOW_COSTS) return;
    const total = blocks.reduce(
      (acc: number, block: ThreadBlock) => acc + (block.cost || 0),
      0
    );
    setTotalCost(Number(total.toFixed(6)));
  }, [blocks]);
  return (
    <div className={styles['thread-wrapper']}>
      <SEOHelmet title={id ? `🧵 ➤ ${title}` : 'New Thread'} />
      <SidebarProvider>
        <div className={styles['thread-topbar']}>
          <Title />
          {id && import.meta.env.VITE_SHOW_COSTS && (
            <i className={styles['thread-costs']}>
              <strong>Total: </strong>
              {totalCost}
            </i>
          )}
          <ThreadOptions />
        </div>
        <section className={styles['thread-main']}>
          <Conversation />
          <InputBox />
          <Disclaimer />
        </section>
        <Sidebar />
      </SidebarProvider>
    </div>
  );
};

export default Thread;

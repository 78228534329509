import EmailLink from '@/components/EmailLink';
import SEOHelmet from '@/components/SEOHelmet';
import styles from './privacy.module.scss';
import { Link } from 'react-router-dom';
const Privacy = () => {
  return (
    <div className={styles['privacy']}>
      <SEOHelmet
        title="Privacy Policy"
        description="Privacy policy for Gannet"
        index={true}
      />
      <div>
        <Link to="/" className={styles['go-back']}>
          &laquo; Go back to GANNET
        </Link>

        <h1>Privacy Policy for GANNET - Humanitarian AI Assistant</h1>

        <h2>Introduction</h2>
        <p>
          Welcome to GANNET, an AI-powered assistant developed by Data Friendly
          Space (DFS). We are committed to ensuring the privacy and security of
          our users' data. This Privacy Policy outlines how we collect, use, and
          protect your information when you use our services.
        </p>

        <h2>Information We Collect</h2>
        <ul>
          <li>
            <strong>Email Address</strong>: The only Personally Identifiable
            Information (PII) we collect is your email address, which is used
            for user account creation and communication purposes.
          </li>
          <li>
            <strong>User Prompts</strong>: We collect the queries and prompts
            you input into GANNET. These are used for internal analysis to
            improve our services and may be sent back to the underlying AI
            system to enhance the accuracy and relevance of responses.
          </li>
        </ul>

        <h2>How We Use Your Information</h2>
        <ul>
          <li>
            <strong>Service Improvement</strong>: User prompts are analyzed to
            identify patterns and improve the quality of responses provided by
            GANNET. This helps us ensure that our AI system remains accurate,
            relevant, and useful.
          </li>
          <li>
            <strong>Internal Analysis</strong>: Data from user interactions is
            used to analyze trends and performance, helping us refine and
            optimize our AI models.
          </li>
          <li>
            <strong>Feedback Loop for LLM Improvement</strong>: When users
            identify answers provided by GANNET as incorrect or unsatisfactory,
            we send the associated prompts and responses back to the underlying
            language model (LLM) service. This feedback loop helps the LLM
            service learn from its mistakes and improve its performance over
            time, reducing the likelihood of repeated inaccurate answers.
          </li>
        </ul>

        <h2>Data Sharing and Protection</h2>
        <ul>
          <li>
            <strong>No Third-Party Sharing</strong>: We do not share your email
            address or any collected data with third parties. All data is used
            solely within DFS to improve GANNET's functionality and user
            experience.
          </li>
          <li>
            <strong>Data Security</strong>: We implement robust security
            measures to protect your data from unauthorized access, alteration,
            disclosure, or destruction. Our security protocols are designed to
            safeguard the information we collect and maintain user privacy.
          </li>
        </ul>

        <h2>User Rights</h2>
        <ul>
          <li>
            <strong>Access and Correction</strong>: You have the right to access
            the information we hold about you. If you believe any information we
            have is incorrect or incomplete, please contact us to update your
            data.
          </li>
          <li>
            <strong>Data Deletion</strong>: You may request the deletion of your
            data at any time. To do so, please contact us, and we will promptly
            remove your information from our systems.
          </li>
        </ul>

        <h2>Contact Information</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or how
          your data is handled, please contact us at <EmailLink />
        </p>

        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. We will notify you of any significant changes by email or
          through a notice on our website.
        </p>

        <h2>Consent</h2>
        <p>
          By using GANNET, you consent to the collection and use of your
          information as outlined in this Privacy Policy. Thank you for trusting
          GANNET and DFS with your data. We are committed to maintaining your
          privacy and ensuring a secure and beneficial user experience. For more
          detailed information about our general data practices, please visit
          our{' '}
          <a href="https://www.datafriendlyspace.org/privacy" target="_blank">
            DFS Privacy Policy
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default Privacy;

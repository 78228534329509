import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useSession from '@/hooks/useSession';
import styles from './root-layout.module.scss';
import { createUserAuth } from '@/utils/session';
import { classnames } from '@/utils/functions';
import getSession from '@/api/users/getSession';
import ClarityScript from '@/components/ClarityScript';
import { gaIdentifyUser } from '@/utils/analytics';
import LoadingSpinner from '@/components/LoadingSpinner';

const RootLayout = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { session, setSession } = useSession();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === '/sign-in') {
      setIsLoading(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (
      ['/', '/sign-up', '/privacy', '/status', '/confirmUser'].includes(
        pathname
      )
    ) {
      setIsLoading(false);
      return;
    } else if (
      import.meta.env.VITE_MAINTENANCE_END &&
      (pathname.includes('/app') || pathname.includes('/sign-in'))
    ) {
      navigate('/status', { replace: true });
      return;
    } else if (session && session.email) {
      setIsLoading(false);
      return;
    } else {
      const checkIfLoggedIn = async () => {
        try {
          const newSession = await getSession();

          if (!newSession) {
            setIsLoading(false);
          } else {
            const { email, sub } = newSession;

            const tmpAuth = await createUserAuth(email, sub);
            setSession(tmpAuth);
            gaIdentifyUser(tmpAuth.sub);

            setIsLoading(false);
            if (pathname === '/sign-in') {
              navigate('/', { replace: true });
            }
          }
        } catch (err) {
          console.error(err);
          setIsLoading(false);
        }
      };
      checkIfLoggedIn();
    }
  }, [session, navigate, pathname, setSession]);

  return (
    <main
      className={classnames(styles.root, { [styles['loading']]: isLoading })}
    >
      <ClarityScript />
      {isLoading ? (
        <div className={styles['loading-wrapper']}>
          <img
            className={styles['loading-logo']}
            src="/gannet-green.png"
            height="150"
            alt="Gannet green logo"
          />
          <LoadingSpinner />
        </div>
      ) : (
        <Outlet />
      )}
    </main>
  );
};

export default RootLayout;

import Button from '@/components/Button';
import SEOHelmet from '@/components/SEOHelmet';
import useSession from '@/hooks/useSession';
import HomeLayout from '@/layouts/HomeLayout';
import { useNavigate } from 'react-router-dom';
import styles from './not-found.module.scss';
import EmailLink from '@/components/EmailLink';
const NotFound = () => {
  const { session } = useSession();
  const navigate = useNavigate();

  return (
    <HomeLayout>
      <SEOHelmet title="Not Found" description="Page not found" />
      <div className={styles['not-found-wrapper']}>
        <h1>Oops, you've encountered an error</h1>
        <p>
          If the error persist, please contact us at <EmailLink />
        </p>

        <Button
          onClick={() => {
            navigate('/');
          }}
        >
          {session.email === '' ? 'Sign in' : 'Go to main page'}
        </Button>
      </div>
    </HomeLayout>
  );
};

export default NotFound;

import SEOHelmet from '@/components/SEOHelmet';
import styles from './explore-data.module.scss';
const ExploreData = () => {
  return (
    <section className={styles['explore-data-wrapper']}>
      <SEOHelmet
        title="Explore Data"
        description="Explore the data in GANNET"
      />
      <iframe
        src="https://lookerstudio.google.com/embed/reporting/24d45a5d-e963-48cd-909b-aedb9bb3a327/page/p_taz4549pgd"
        allowFullScreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      ></iframe>
    </section>
  );
};

export default ExploreData;

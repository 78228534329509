import { NavLink } from 'react-router-dom';
import styles from './home-layout.module.scss';
import { FC, ReactNode } from 'react';
import { classnames } from '@/utils/functions';
import useSession from '@/hooks/useSession';

interface HomeLayoutProps {
  showNav?: boolean;
  children: ReactNode;
}

const HomeLayout: FC<HomeLayoutProps> = ({ showNav = true, children }) => {
  const { session } = useSession();

  return (
    <section className={styles['home-layout']}>
      <div className={styles['img-wrapper']}>
        <img src="/gannet-bg.jpeg" alt="Gannet.ai background" />
      </div>

      <div
        className={classnames(styles['content'], {
          [styles['show-nav']]: showNav,
        })}
      >
        {showNav && (
          <nav className={styles['nav']}>
            <NavLink className={styles['nav-link']} to="/">
              {session.email ? 'App' : 'Sign In'}
            </NavLink>
            <NavLink className={styles['nav-link']} to="/privacy">
              Privacy
            </NavLink>
          </nav>
        )}

        {children}
      </div>
    </section>
  );
};

export default HomeLayout;

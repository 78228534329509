import SEOHelmet from '@/components/SEOHelmet';
import { FC } from 'react';
import styles from './users-management.module.scss';
import ChevronLeftIcon from '@/assets/icons/chevron-left.svg?react';
import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from 'react';
import { classnames } from '@/utils/functions';
import MsgParagraph from '@/components/MsgParagraph';
import { post } from 'aws-amplify/api';
import { AWS_USERS_API } from '@/config/aws';
import { User, UserStatus } from '@/models/user';
import useSession from '@/hooks/useSession';
import fetchUsers from '@/api/users/fetchUsers';

const UsersManagement = () => {
  const { session, pushUserEvent } = useSession();
  const [query, setQuery] = useState<string>('');
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortDesc, setSortDesc] = useState<boolean>(true);
  const [sortColumn, setSortColumn] = useState<keyof User>('date');
  const columns: { label: string; value: keyof User }[] = [
    { label: 'Date', value: 'date' },
    { label: 'Email', value: 'email' },
    { label: 'Name', value: 'name' },
    { label: 'Organization', value: 'organization' },
    { label: 'Use Case', value: 'useCase' },
    { label: 'Status', value: 'status' },
  ];
  const getAllUsers = async () => {
    try {
      setLoading(true);

      const fetchedUsers = await fetchUsers();

      setUsers(fetchedUsers);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onSearch: (
    e: FormEvent<HTMLFormElement> | ChangeEvent<HTMLInputElement>
  ) => void = (e) => {
    let search = '';
    if (e.type === 'submit') {
      e.preventDefault();
      const formData = new FormData(
        (e as FormEvent<HTMLFormElement>).currentTarget
      );
      search = formData.get('search') as string;
    } else {
      search = (e as ChangeEvent<HTMLInputElement>).target.value;
    }

    setQuery(search);
  };

  const toggleUserStatus = async (
    id: string,
    email: string,
    status: UserStatus
  ) => {
    try {
      if (status !== 'WAITING') return;

      const confirmationText = `Confirm if you want to allow the following user to access the platform: \n"${email}"`;
      if (confirm(confirmationText)) {
        setLoading(true);
        const { response } = await post({
          apiName: AWS_USERS_API,
          path: `/user-admin/user-waitlist/${id}/decision`,
          options: {
            body: {
              action: 'APPROVE',
              reason: `All parameters check out. approved by ${session.email}`,
            },
          },
        });
        const responsePayload = (await (
          await response
        ).body.json()) as unknown as { success: boolean; message: string };

        if (responsePayload.success) {
          pushUserEvent('user_approved');
          setUsers((prevUsers) =>
            prevUsers.map((user) =>
              user.id === id
                ? {
                    ...user,
                    status: 'ACTIVE',
                  }
                : user
            )
          );
        } else {
          alert(responsePayload.message);
        }
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onSortingChange = (column: keyof User) => {
    setSortColumn(column);
    if (sortColumn === column) {
      setSortDesc((current) => !current);
    } else {
      setSortDesc(true);
    }
  };

  const filteredUsers = useMemo(() => {
    return users.filter(
      (user) =>
        user.email.toLowerCase().includes(query.toLowerCase()) ||
        user.name.toLowerCase().includes(query.toLowerCase()) ||
        user.organization.toLowerCase().includes(query.toLowerCase()) ||
        (user.useCase &&
          user.useCase.toLowerCase().includes(query.toLowerCase()))
    );
  }, [users, query]);

  const sortedUsers = useMemo(() => {
    return filteredUsers.sort((a, b) => {
      let result = 0;

      if (a[sortColumn] < b[sortColumn]) {
        result = -1;
      } else if (a[sortColumn] > b[sortColumn]) {
        result = 1;
      } else {
        return b.date.getTime() - a.date.getTime();
      }

      return (sortColumn === 'date' ? !sortDesc : sortDesc) ? result : -result;
    });
  }, [filteredUsers, sortColumn, sortDesc]);

  useEffect(() => {
    getAllUsers();
  }, []);
  return (
    <div className={styles['users-management-wrapper']}>
      <SEOHelmet title="User Management" />
      <h1>Users Management</h1>

      {loading ? (
        <MsgParagraph>Loading...</MsgParagraph>
      ) : (
        <>
          <form onSubmit={onSearch}>
            <input
              type="search"
              id="search"
              name="search"
              placeholder="Type to filter by email, name, organization or use case keywords.."
              onChange={onSearch}
              required
            />
          </form>
          {query !== '' && status === 'success' && sortedUsers.length === 0 ? (
            <MsgParagraph>
              Search for<strong>"{query}"</strong> returned no results.
            </MsgParagraph>
          ) : (
            <>
              <p className={styles['counter']}>
                {query
                  ? `Filter matches ${filteredUsers.length} of ${users.length} Users`
                  : `${users.length} Total Users (${
                      users.filter(({ status }) => status === 'WAITING').length
                    } Waiting for Approval)`}
              </p>
              <div className={styles['table-wrapper']}>
                <table>
                  <thead>
                    <tr>
                      {columns.map(({ label, value }) => (
                        <th key={value}>
                          <SortingButton
                            column={value}
                            label={label}
                            currentColumn={sortColumn}
                            desc={sortDesc}
                            currentDesc={sortDesc}
                            onClick={() => onSortingChange(value)}
                          />
                        </th>
                      ))}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map(
                      ({
                        id,
                        email,
                        name,
                        organization,
                        useCase,
                        date,
                        status,
                      }) => (
                        <tr key={id} id={id}>
                          <td>{date.toLocaleString()}</td>
                          <td>
                            <p className={styles['email-cell']}>
                              {email.split('@').map((part, index) => {
                                return (
                                  <span key={index}>
                                    {index === 1 && '@'}
                                    {part}
                                  </span>
                                );
                              })}
                            </p>
                          </td>
                          <td>{name}</td>
                          <td>
                            <strong>{organization}</strong>
                          </td>
                          <td>{useCase}</td>
                          <td
                            className={classnames(
                              styles['status'],
                              styles[`status-${status.toLowerCase()}`]
                            )}
                          >
                            <strong>{status}</strong>
                          </td>
                          <td>
                            {status === 'WAITING' && (
                              <button
                                className={classnames(styles['status-btn'])}
                                onClick={() => {
                                  toggleUserStatus(id, email, status);
                                }}
                              >
                                Allow
                              </button>
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

interface SortingButtonProps {
  column: keyof User;
  label: string;
  currentColumn: keyof User;
  desc: boolean;
  currentDesc: boolean;
  onClick: () => void;
}
const SortingButton: FC<SortingButtonProps> = ({
  column,
  label,
  currentColumn,
  currentDesc,
  onClick,
}) => {
  return (
    <button
      className={classnames(styles['sorting-button'], {
        [styles['active-col']]: currentColumn === column,
        [styles['is-asc']]: !currentDesc,
      })}
      onClick={onClick}
    >
      {label}

      <ChevronLeftIcon className={styles['sorting-order-icon']} />
    </button>
  );
};

export default UsersManagement;

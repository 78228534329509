import { Outlet } from 'react-router-dom';
import Navbar from '@/components/Navbar';
import styles from './app-layout.module.scss';
import { ThreadProvider } from '@/pages/Thread/context/ThreadProvider';
import SEOHelmet from '@/components/SEOHelmet';

const AppLayout = () => {
  return (
    <>
      <SEOHelmet title="GANNET APP" />
      <ThreadProvider>
        <div className={styles['app-layout']}>
          <Navbar />
          <div className={styles['main-content']}>
            <Outlet />
          </div>
        </div>
      </ThreadProvider>
    </>
  );
};

export default AppLayout;
